<template>
    <div>
      <Lottery />
    </div>
</template>

<script>
import Lottery from "../components/lottery/Lottery.vue";
export default {
  components: {
    Lottery: Lottery,
  },
}
</script>